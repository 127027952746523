

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MR-X{
  margin-left: 3px;
}
.myNavFont{
  font-family: 'Poppins' sans-serif;
  font-weight: bolder;
  font-size: large;
  font-palette: light;

}
.myFont2{
  font-family: 'Poppins' sans-serif;
  font-weight:normal;
  font-size: small;
  font-palette: light;
}
.myFont{
  font-family: 'Poppins' sans-serif;
  font-weight: bolder;
  font-size: large;
  font-palette: light;
}.myFontPhone{
  font-family: 'Poppins' sans-serif;
  font-weight: bolder;
  
  font-palette: light;
}
.myFontSmall{
  font-family: 'Poppins' sans-serif;
  font-weight: bolder;
  font-size:medium;
  font-palette: light;
}
.myText {
  font-family: 'Quicksand', sans-serif;
  font-size:larger;
  font-weight:700;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}
.myTextAbout {
  font-family: 'Quicksand', sans-serif;
  font-size:medium;
  font-weight:500;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}
.myTextAbout2 {
  font-family: 'Quicksand', sans-serif;
  font-size:larger;
  font-weight: 700;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myTextAbout3 {
  font-family: 'Quicksand', sans-serif;
  font-size:larger;
  font-weight:500;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}
.myTextAbout4 {
  font-family: 'Quicksand', sans-serif;
  font-size:xx-large;
  font-weight: 700;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myTextAbout5 {
  font-family: 'Quicksand', sans-serif;
  font-size:large;
  font-weight:700;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}
.myTextAbout10 {
  font-family: 'Quicksand', sans-serif;
  
  font-weight:500;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myTextAbout11 {
  font-family: 'Quicksand', sans-serif;
  
 
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myTextAbout123 {
  font-family: 'Quicksand', sans-serif;
  font-size:large;
  font-weight:500;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.ProfileHeaderTxt {
  font-family: 'Quicksand', sans-serif;
  font-size:30px;
  font-weight:1000;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.ProfileContTxt {
  font-family: 'Quicksand', sans-serif;
  font-size:50px;
  font-weight:600;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText {
  font-family: 'Quicksand', sans-serif;
  font-size:larger;
  font-weight:1;
  opacity: 0.5;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText2 {
  font-family: 'Quicksand', sans-serif;
  font-size:x-large;
  font-weight:1000;
  opacity: 0.65;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText3 {
  font-family: 'Quicksand', sans-serif;
  font-size:x-large;
  font-weight:500;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText4 {
  font-family: 'Quicksand', sans-serif;
  font-size:medium;
  font-weight:500;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText3phone {
  font-family: 'Quicksand', sans-serif;
  font-size:small;
  font-weight:500;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}.myInfoText4phone {
  font-family: 'Quicksand', sans-serif;
  font-size:small;
  font-weight:500;
  color: white;
  /* İsterseniz diğer font özelliklerini buraya ekleyebilirsiniz */
}
.boxShadow{
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.boxShadow2{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
